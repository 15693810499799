import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"

export default class extends Controller {
  static targets = [
    "error",
    "otherMethod",
    "tabButton",
    "tabContent",
    "cardElement",
    "cardErrors",
    "linkResult",
    "linkUrl"
  ]

  static values = {
    key: String
  }

  async connect() {
    // Initialize default tab
    this.switchTab({ currentTarget: this.tabButtonTargets.find(b => b.dataset.tab === 'manual') })

    // Initialize Stripe
    try {
      this.stripe = await loadStripe(this.keyValue)
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', {
        style: {
          base: {
            color: '#fff',
            fontFamily: '"Inter", system-ui, sans-serif',
            fontSize: '16px',
            '::placeholder': {
              color: '#64748b'
            }
          }
        }
      })
      this.card.mount(this.cardElementTarget)
      this.card.addEventListener('change', this.handleCardChange.bind(this))
    } catch (error) {
      console.error('Stripe initialization error:', error)
    }
  }

  disconnect() {
    if (this.card) {
      this.card.unmount()
    }
  }

  switchTab(event) {
    const selectedTab = event.currentTarget.dataset.tab

    this.tabButtonTargets.forEach(button => {
      button.classList.toggle('bg-blue-600', button.dataset.tab === selectedTab)
      button.classList.toggle('text-white', button.dataset.tab === selectedTab)
      button.classList.toggle('text-space-400', button.dataset.tab !== selectedTab)
    })

    this.tabContentTargets.forEach(content => {
      content.classList.toggle('hidden', content.dataset.tab !== selectedTab)
    })
  }

  toggleOtherMethod(event) {
    const showOther = event.target.value === 'other'
    this.otherMethodTarget.classList.toggle('hidden', !showOther)
  }

  handleCardChange(event) {
    if (event.error) {
      this.cardErrorsTarget.textContent = event.error.message
    } else {
      this.cardErrorsTarget.textContent = ''
    }
  }

  async submitManual(event) {
    event.preventDefault()
    const form = event.target

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: new FormData(form)
      })

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.errors?.join(', ') || 'Failed to process payment')
      }

      // Refresh the page to show updated data
      window.location.reload()
    } catch (error) {
      console.error('Payment submission error:', error)
      this.errorTarget.textContent = error.message || "An error occurred while processing the payment"
    }
  }

  async processCard(event) {
    event.preventDefault()
    const amount = this.element.querySelector('[name="card_amount"]').value

    if (!amount || amount <= 0) {
      this.cardErrorsTarget.textContent = 'Please enter a valid amount'
      return
    }

    try {
      const { token, error } = await this.stripe.createToken(this.card)
      if (error) {
        this.cardErrorsTarget.textContent = error.message
        return
      }

      const response = await fetch(this.element.action.replace('manual', 'card'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({
          token: token.id,
          amount: amount
        })
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error || 'Payment processing failed')
      }

      // Success - refresh the page
      window.location.reload()
    } catch (error) {
      console.error('Payment processing error:', error)
      this.cardErrorsTarget.textContent = error.message || 'Payment processing failed'
    }
  }

  async generateLink(event) {
    event.preventDefault()
    const amount = this.element.querySelector('[name="link_amount"]').value
    const expireAfter = this.element.querySelector('[name="expire_after"]').value

    if (!amount || amount <= 0) {
      this.errorTarget.textContent = 'Please enter a valid amount'
      return
    }

    try {
      const response = await fetch(this.element.action.replace('manual', 'generate_link'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({
          amount: amount,
          expire_after: expireAfter
        })
      })

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.error || 'Failed to generate payment link')
      }

      const data = await response.json()
      this.linkResultTarget.classList.remove('hidden')
      this.linkUrlTarget.value = data.url
    } catch (error) {
      console.error('Link generation error:', error)
      this.errorTarget.textContent = error.message || 'Failed to generate payment link'
    }
  }

  copyLink() {
    this.linkUrlTarget.select()
    document.execCommand('copy')

    // Show temporary "Copied!" feedback
    const button = event.target
    const originalText = button.textContent
    button.textContent = 'Copied!'
    setTimeout(() => {
      button.textContent = originalText
    }, 2000)
  }
}