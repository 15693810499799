// app/javascript/controllers/stripe_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "cardElement", "error", "submit"]

  connect() {
    if (!window.Stripe) {
      console.error("Stripe.js not loaded")
      return
    }

    this.stripe = Stripe(this.data.get("publishableKey") || window.stripeKey)
    this.elements = this.stripe.elements()

    // Create card element
    this.card = this.elements.create("card", {
      style: {
        base: {
          color: '#ffffff',
          fontFamily: '"Inter", sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#6b7280',
          },
        },
        invalid: {
          color: '#ef4444',
          iconColor: '#ef4444',
        },
      },
    })

    // Mount card element
    this.card.mount(this.cardElementTarget)

    // Add event listeners
    this.card.on('change', this.handleChange.bind(this))
  }

  async handleSubmit(event) {
    event.preventDefault()
    this.setLoading(true)
    this.errorTarget.textContent = ""

    try {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      })

      if (error) {
        throw error
      }

      // Submit payment to server
      const response = await fetch('/api/payments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          order_id: this.formTarget.dataset.orderId,
          payment_type: 'card'
        })
      })

      const result = await response.json()

      if (result.errors) {
        throw new Error(result.errors.join(', '))
      }

      // Confirm the payment with Stripe
      const { error: confirmError } = await this.stripe.confirmCardPayment(
        result.client_secret
      )

      if (confirmError) {
        throw confirmError
      }

      // Reload page on success to show updated payment status
      window.location.reload()

    } catch (error) {
      this.errorTarget.textContent = error.message
    } finally {
      this.setLoading(false)
    }
  }

  handleChange(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  setLoading(loading) {
    this.submitTarget.disabled = loading
    this.submitTarget.innerHTML = loading ?
      '<i class="fas fa-spinner fa-spin mr-2"></i>Processing...' :
      'Process Payment'
  }

  disconnect() {
    if (this.card) {
      this.card.destroy()
    }
  }
}