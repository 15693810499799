import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "results"];

  connect() {
    this.timeout = null;
  }

  search() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      const query = this.inputTarget.value.trim();
      if (query.length < 2) {
        this.resultsTarget.innerHTML = "";
        return;
      }

      try {
        const response = await fetch(`/api/v1/products/search?query=${encodeURIComponent(query)}`);
        const data = await response.json();

        if (data.results?.length > 0) {
          this.resultsTarget.innerHTML = this.buildResultsHtml(data.results);
        } else {
          this.resultsTarget.innerHTML = this.buildEmptyState();
        }
      } catch (error) {
        console.error("Search failed:", error);
        this.resultsTarget.innerHTML = this.buildErrorState();
      }
    }, 300);
  }

  selectProduct(e) {
    const item = e.currentTarget;
    const productData = {
      productId: item.dataset.productId,
      productSku: item.dataset.productSku,
      productTitle: item.dataset.productTitle,
      productCost: parseFloat(item.dataset.productCost || 0),
      productBuilderCost: parseFloat(item.dataset.productBuilderCost || 0),
      productImage: item.dataset.productImage,
    };

    console.log("Selected Product Data:", productData); // Debug log to ensure correct data

    const template = document.querySelector('[data-nested-form-target="template"]');
    const content = template.content.cloneNode(true);
    const wrapper = content.querySelector(".nested-form-wrapper");
    const newIndex = new Date().getTime();

    wrapper.querySelectorAll("input").forEach((input) => {
      input.name = input.name.replace("NEW_RECORD", newIndex);
    });

    wrapper.querySelector('input[name*="[product_variant_id]"]').value = productData.productSku;
    wrapper.querySelector('input[name*="[sku]"]').value = productData.productSku;
    wrapper.querySelector('input[name*="[cost]"]').value = productData.productCost.toFixed(2);
    wrapper.querySelector('input[name*="[builder_cost]"]').value = productData.productBuilderCost.toFixed(2);
    wrapper.querySelector('input[name*="[quantity]"]').value = 1;

    wrapper.querySelector(".product-title").textContent = productData.productTitle;
    wrapper.querySelector(".product-sku").textContent = `SKU: ${productData.productSku}`;

    if (productData.productImage) {
      wrapper.querySelector(".w-24.h-24").innerHTML = `
        <img src="${productData.productImage}" class="w-full h-full object-cover rounded-lg" alt="${productData.productTitle}" />
      `;
    }

    document.querySelector('[data-nested-form-target="target"]').appendChild(wrapper);
    document.dispatchEvent(new CustomEvent("po-order-totals:update"));

    this.inputTarget.value = "";
    this.resultsTarget.innerHTML = "";
  }

  buildResultsHtml(products) {
    return `
      <div class="bg-space-900 rounded-md shadow-lg border border-space-800 max-h-96 overflow-auto">
        <ul class="divide-y divide-space-800">
          ${products
        .map((product) => {
          const escapedSku = (product.sku || "").replace(/"/g, "&quot;");
          const escapedTitle = (product.title || "").replace(/"/g, "&quot;");
          const escapedBrand = (product.brand || "").replace(/"/g, "&quot;");
          const escapedImage = (product.image || "").replace(/"/g, "&quot;");
          const stockCount = product.stock?.reduce((sum, s) => sum + (s.available || 0), 0) || 0;

          return `
                <li class="p-4 hover:bg-space-800 cursor-pointer" 
                    data-action="click->po-product-search#selectProduct"
                    data-product-id="${product.id}"
                    data-product-sku="${escapedSku}"
                    data-product-title="${escapedTitle}"
                    data-product-brand="${escapedBrand}"
                    data-product-cost="${product.cost || 0}"
                    data-product-builder-cost="${product.builder_cost || 0}"
                    data-product-image="${escapedImage}">
                  <div class="flex items-center gap-4">
                    ${product.image
              ? `<img src="${product.image}" class="w-16 h-16 object-cover rounded bg-space-800" alt="${escapedTitle}" />`
              : `<div class="w-16 h-16 bg-space-800 rounded flex-shrink-0"></div>`
            }
                    <div class="flex-1 min-w-0">
                      <div class="font-bold truncate">${escapedTitle}</div>
                      <div class="text-sm text-space-400">SKU: ${escapedSku}</div>
                      <div class="text-sm text-space-400">${escapedBrand}</div>
                    </div>
                    <div class="flex gap-1 text-right">
                      <div class="font-bold">${this.formatCurrency(product.cost)}</div>
                      <div class="font-bold">BC: ${this.formatCurrency(product.builder_cost)}</div>
                      <div class="text-sm text-space-400">Stock: ${stockCount}</div>
                    </div>
                  </div>
                </li>
              `;
        })
        .join("")}
        </ul>
      </div>
    `;
  }

  buildEmptyState() {
    return `
      <div class="bg-space-900 rounded-md shadow-lg border border-space-800 p-8 text-center text-space-400">
        <p>No products found</p>
      </div>
    `;
  }

  buildErrorState() {
    return `
      <div class="bg-space-900 rounded-md shadow-lg border border-space-800 p-8 text-center text-red-500">
        <p>Error performing search</p>
      </div>
    `;
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
