import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel", "content", "collapseButton", "collapseIcon"];
  static values = {
    collapsed: { type: Boolean, default: true },
    selectedTab: { type: Number, default: 0 },
  };

  connect() {
    // Load saved state from localStorage
    const savedCollapsed = localStorage.getItem("tabsCollapsed");
    const savedTab = localStorage.getItem("selectedTab");

    // Initialize with saved or default values
    this.collapsedValue =
      savedCollapsed !== null ? JSON.parse(savedCollapsed) : true;
    this.selectedTabValue =
      savedTab !== null ? parseInt(savedTab) : 0;

    // Apply initial state
    this.showTab(this.selectedTabValue);
    this.updateCollapsedState();
  }

  select(event) {
    event.preventDefault();
    const index = parseInt(event.currentTarget.dataset.index);

    // Expand the content if it is collapsed
    if (this.collapsedValue) {
      this.collapsedValue = false;
      this.updateCollapsedState(); // Ensure content becomes visible
    }

    // Switch to the selected tab
    this.selectedTabValue = index;
    this.showTab(index);

    // Save the selected tab to localStorage
    localStorage.setItem("selectedTab", index);
  }

  toggle(event) {
    event.preventDefault();

    // Toggle collapsed/expanded state
    this.collapsedValue = !this.collapsedValue;
    this.updateCollapsedState();

    // Save the collapsed state to localStorage
    localStorage.setItem("tabsCollapsed", this.collapsedValue);

    // Trigger resize event for any charts or responsive elements
    window.dispatchEvent(new Event("resize"));
  }

  updateCollapsedState() {
    // Show or hide the content area
    if (this.collapsedValue) {
      this.contentTarget.classList.add("hidden");
    } else {
      this.contentTarget.classList.remove("hidden");
    }

    // Rotate the collapse icon
    this.collapseIconTarget.style.transform = this.collapsedValue
      ? "rotate(180deg)"
      : "";
  }

  showTab(index) {
    // Update tab styling
    this.tabTargets.forEach((tab, i) => {
      const isSelected = i === index;

      tab.classList.remove(
        "tab-active",
        "tab-inactive",
        "border-blue-500",
        "border-transparent",
        "text-white",
        "bg-blue-500"
      );

      if (isSelected) {
        tab.classList.add(
          "tab-active",
          "border-b-2",
          "border-blue-500",
          "text-white",
          "bg-blue-500"
        );
      } else {
        tab.classList.add(
          "tab-inactive",
          "border-b-2",
          "border-transparent",
          "text-white",
          "hover:text-space-100",
          "hover:bg-space-800/30"
        );
      }
    });

    // Show or hide the corresponding panels
    this.panelTargets.forEach((panel, i) => {
      panel.classList.toggle("hidden", i !== index);
    });
  }
}
