import consumer from "./consumer";

// Track active subscriptions
const activeSubscriptions = new Map();

function isOrderEditPage() {
  return !!document.querySelector('header h1.text-xl');
}

function getOrderIdFromPage() {
  const pathMatch = window.location.pathname.match(/\/orders\/([^\/]+)(\/edit)?$/);
  if (pathMatch) {
    return pathMatch[1];
  }

  const orderElement = document.querySelector('[data-order-id]');
  return orderElement ? orderElement.dataset.orderId : null;
}

function cleanupSubscription(orderId) {
  const subscription = activeSubscriptions.get(orderId);
  if (subscription) {
    console.log(`Cleaning up subscription for order ${orderId}`);
    subscription.unsubscribe();
    activeSubscriptions.delete(orderId);
  }
}

function renderActiveUsers(users, containerSelector) {
  const container = document.querySelector(containerSelector);
  if (!container) {
    console.log(`Container ${containerSelector} not found`);
    return;
  }

  console.log('Rendering active users:', users);

  const usersHtml = users
    .map(user => `
      <div class="relative inline-block group">
        <img 
          src="${user.profile_image_url}" 
          alt="${user.username}" 
          class="w-8 h-8 rounded-full border border-gray-300"
        >
        <div class="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden group-hover:block 
                    bg-black text-white text-xs rounded py-1 px-2">
          ${user.username}
        </div>
      </div>
    `)
    .join('');

  container.innerHTML = usersHtml;
}

function broadcastToAllContainers(orderId, users) {
  // Try both containers for maximum compatibility
  const editContainer = document.querySelector('#active-users');
  if (editContainer) {
    renderActiveUsers(users, '#active-users');
  }

  const indexContainer = document.querySelector(`#active-users-order-${orderId}`);
  if (indexContainer) {
    renderActiveUsers(users, `#active-users-order-${orderId}`);
  }
}

function initializeEditPageSubscription(orderId) {
  if (!activeSubscriptions.has(orderId)) {
    const subscription = consumer.subscriptions.create(
      {
        channel: "OrderActivityChannel",
        order_id: orderId,
        viewing: true
      },
      {
        connected() {
          console.log(`Connected to OrderActivityChannel for order ${orderId}`);
        },
        disconnected() {
          console.log(`Disconnected from OrderActivityChannel for order ${orderId}`);
        },
        received(data) {
          console.log(`Received data for order ${orderId}:`, data);
          if (data.active_users) {
            broadcastToAllContainers(orderId, data.active_users);
          }
        }
      }
    );

    activeSubscriptions.set(orderId, subscription);
  }
}

function initializeSubscriptions() {
  if (isOrderEditPage()) {
    const orderId = getOrderIdFromPage();
    if (orderId) {
      console.log('Initializing edit page subscription');
      initializeEditPageSubscription(orderId);
    }
  }
}

// Clean up when leaving page
document.addEventListener("turbo:before-visit", () => {
  activeSubscriptions.forEach((_, orderId) => cleanupSubscription(orderId));
});

// Handle tab visibility
document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "hidden") {
    activeSubscriptions.forEach((_, orderId) => cleanupSubscription(orderId));
  } else if (isOrderEditPage()) {
    initializeSubscriptions();
  }
});

// Initialize on page load
document.addEventListener("turbo:load", () => {
  console.log('Page loaded, initializing subscriptions');
  initializeSubscriptions();
});

// Handle Turbo frame updates
document.addEventListener("turbo:frame-load", (event) => {
  if (event.target.id === 'order-form') {
    console.log('Order form frame loaded, reinitializing subscriptions');
    initializeSubscriptions();
  }
});

// Global subscription for index page updates
const globalSubscription = consumer.subscriptions.create(
  { channel: "OrderActivityChannel" },
  {
    connected() {
      console.log('Connected to global OrderActivityChannel');
    },
    received(data) {
      console.log('Global subscription received:', data);
      if (data.active_users && data.order_id) {
        broadcastToAllContainers(data.order_id, data.active_users);
      }
    }
  }
);