import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog", "backdrop", "panel"]

  connect() {
    console.log("============")
    console.log("Payment Dialog Controller Connected!")
    console.log("Element:", this.element)
    console.log("Has Dialog Target:", this.hasDialogTarget)
    console.log("Has Backdrop Target:", this.hasBackdropTarget)
    console.log("Has Panel Target:", this.hasPanelTarget)
    console.log("============")
  }

  openDialog(event) {
    console.log("============")
    console.log("Open Dialog Called!")
    console.log("Event:", event)
    console.log("Has Dialog Target:", this.hasDialogTarget)
    if (this.hasDialogTarget) {
      console.log("Opening dialog...")
      this.dialogTarget.classList.remove("hidden")
      document.body.classList.add("overflow-hidden")
    }
    console.log("============")
  }

  closeDialog(event) {
    console.log("Close Dialog Called")
    if (!event || event.target === this.backdropTarget) {
      this.dialogTarget.classList.add("hidden")
      document.body.classList.remove("overflow-hidden")
    }
  }

  closeWithButton() {
    console.log("Close Button Clicked")
    this.dialogTarget.classList.add("hidden")
    document.body.classList.remove("overflow-hidden")
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      this.closeDialog()
    }
  }

  preventDialogClose(event) {
    event.stopPropagation()
  }
}