import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]
  static values = { activeTab: String }

  connect() {
    // Restore previously active tab or default to first tab
    const savedTab = this.activeTabValue || this.tabTargets[0].dataset.tab
    this.activateTab(savedTab)
  }

  change(event) {
    const selectedTab = event.currentTarget.dataset.tab
    this.activateTab(selectedTab)
  }

  activateTab(tabName) {
    // Remove active state from all tabs
    this.tabTargets.forEach(tab => {
      tab.dataset.active = tab.dataset.tab === tabName ? "true" : "false"
    })

    // Hide all content
    this.contentTarget.querySelectorAll('[data-tab]').forEach(content => {
      content.classList.toggle('hidden', content.dataset.tab !== tabName)
    })

    // Save active tab
    this.activeTabValue = tabName
  }
}