import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["roleSelect"]

  connect() {
    const departmentSelect = this.element.querySelector('select')
    const currentRoleId = this.roleSelectTarget.value

    if (departmentSelect && departmentSelect.value) {
      this.updateRoles(currentRoleId)
    }
  }

  async updateRoles(event) {
    const departmentId = this.element.querySelector('select').value
    const roleSelect = this.roleSelectTarget
    const currentRoleId = event?.target ? undefined : event // If called from connect(), event is the roleId
    const orgSubdomain = window.location.pathname.split('/')[2]

    if (!departmentId) {
      roleSelect.innerHTML = '<option value="">Select Job Role</option>'
      return
    }

    try {
      const response = await fetch(`/api/v1/${orgSubdomain}/departments/${departmentId}/roles`, {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const roles = await response.json()

      roleSelect.innerHTML = '<option value="">Select Job Role</option>' +
        roles.map(role =>
          `<option value="${role.id}" ${role.id == currentRoleId ? 'selected' : ''}>${role.name}</option>`
        ).join('')
    } catch (error) {
      console.error("Error fetching roles:", error)
      roleSelect.innerHTML = '<option value="">Error loading roles</option>'
    }
  }
}