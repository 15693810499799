import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["messageInput"]

  connect() {
    this.currentChat = null
    this.justSentMessageId = null
    this.setupSubscription()
    this.fetchOnlineUsers()
    console.log("Messenger Initialized")
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  setupSubscription() {
    this.subscription = consumer.subscriptions.create(
      { channel: "MessengerChannel" },
      {
        received: this.handleMessage.bind(this),
        connected: () => {
          console.log("Connected to MessengerChannel")
        },
        disconnected: () => {
          console.log("Disconnected from MessengerChannel")
        }
      }
    )
  }

  togglePanel() {
    const panel = document.getElementById("messenger-panel")
    if (panel.classList.contains('translate-x-full')) {
      panel.classList.remove('translate-x-full')
      panel.classList.add('translate-x-0')
    } else {
      panel.classList.remove('translate-x-0')
      panel.classList.add('translate-x-full')
    }
  }

  showUserList() {
    document.getElementById("messenger-title").textContent = "Online Users"
    document.getElementById("user-list").classList.remove("hidden")
    document.getElementById("chat-view").classList.add("hidden")
    this.currentChat = null
  }

  showChat(userId, username) {
    this.currentChat = userId
    document.getElementById("messenger-title").textContent = username
    document.getElementById("user-list").classList.add("hidden")
    document.getElementById("chat-view").classList.remove("hidden")
    this.loadChatHistory(userId)
  }

  async fetchOnlineUsers() {
    try {
      const response = await fetch('/api/online_users')
      const users = await response.json()
      this.renderUserList(users)
    } catch (error) {
      console.error("Error fetching online users:", error)
    }
  }

  renderUserList(users) {
    const userList = document.getElementById("user-list")
    userList.innerHTML = users.map(user => `
      <div class="flex items-center justify-between p-2 hover:bg-zinc-800 rounded cursor-pointer"
           data-action="click->messenger#startChat"
           data-user-id="${user.id}">
        <div class="flex items-center gap-2">
          <img src="${user.profile_image_url}" alt="${user.username}" 
               class="w-8 h-8 rounded-full">
          <span class="text-zinc-100">${user.name}</span>
        </div>
        <div class="w-2 h-2 rounded-full ${user.online ? 'bg-green-500' : 'bg-zinc-500'}"></div>
      </div>
    `).join('')
  }

  startChat(event) {
    const userId = event.currentTarget.dataset.userId
    const username = event.currentTarget.querySelector('span').textContent
    this.showChat(userId, username)
  }

  async loadChatHistory(userId) {
    try {
      const response = await fetch(`/api/messages/${userId}`)
      const messages = await response.json()
      this.renderMessages(messages)
    } catch (error) {
      console.error("Error loading chat history:", error)
    }
  }

  renderMessages(messages) {
    const messagesContainer = document.getElementById("chat-messages")
    messagesContainer.innerHTML = messages.map(message => this.renderMessage(message)).join('')
    messagesContainer.scrollTop = messagesContainer.scrollHeight
  }

  renderMessage(message) {
    const isCurrentUser = message.sender_id === parseInt(document.querySelector('meta[name="current-user-id"]').content);
    return `
      <div class="flex ${isCurrentUser ? 'justify-end' : 'justify-start'}">
        <div class="max-w-[75%] ${isCurrentUser ? 'bg-green-600' : 'bg-zinc-800'} 
                    text-zinc-100 rounded-lg px-4 py-2">
          <p>${message.content}</p>
          <span class="text-xs text-zinc-300">${message.time}</span>
        </div>
      </div>
    `;
  }

  handleMessage(data) {
    console.log('Received message data:', data);

    if (data.type === 'message') {
      const messageData = data.message || data;
      const currentUserId = parseInt(document.querySelector('meta[name="current-user-id"]').content);

      // Only process if we're in the relevant chat
      const currentChatId = parseInt(this.currentChat);
      const senderId = parseInt(messageData.sender_id);
      const receiverId = parseInt(messageData.receiver_id);

      if ((currentChatId === senderId || currentChatId === receiverId) &&
        senderId !== currentUserId) {
        // Only render messages we received, not ones we sent
        const messages = document.getElementById("chat-messages");
        if (messages) {
          messages.insertAdjacentHTML('beforeend', this.renderMessage(messageData));
          messages.scrollTop = messages.scrollHeight;
        }
      }
    } else if (data.type === 'user_status') {
      this.fetchOnlineUsers();
    }
  }

  async sendMessage(event) {
    event.preventDefault()
    if (!this.currentChat) return

    const content = this.messageInputTarget.value.trim()
    if (!content) return

    try {
      const response = await fetch('/api/messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify({
          receiver_id: this.currentChat,
          content: content
        })
      });

      if (!response.ok) {
        const error = await response.json();
        console.error("Error:", error.error);
        return;
      }

      const messageData = await response.json();

      // Immediately render our own message
      const messages = document.getElementById("chat-messages");
      messages.insertAdjacentHTML('beforeend', this.renderMessage(messageData));
      messages.scrollTop = messages.scrollHeight;

      this.messageInputTarget.value = '';
    } catch (error) {
      console.error("Error sending message:", error)
    }
  }

}