import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["itemCount", "totalCost"]

  connect() {
    this.updateTotals()
  }

  updateTotals() {
    const items = document.querySelectorAll('.nested-form-wrapper')
    let totalCost = 0

    items.forEach(item => {
      const quantity = parseInt(item.querySelector('input[name*="[quantity]"]').value) || 0
      const cost = parseFloat(item.querySelector('input[name*="[cost]"]').value) || 0
      const builder_cost = parseFloat(item.querySelector('input[name*="[builder_cost]"]').value) || 0
      totalCost += quantity * cost
    })

    this.itemCountTarget.textContent = items.length
    this.totalCostTarget.textContent = this.formatCurrency(totalCost)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }
}