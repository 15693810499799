import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    // Show first panel by default
    this.showFirstPanel()
  }

  change(event) {
    event.preventDefault()
    this.hideAllPanels()

    // Find the clicked tab
    const clickedTab = event.currentTarget
    const allTabs = this.tabTargets
    const tabIndex = allTabs.indexOf(clickedTab)

    // Show corresponding panel
    if (tabIndex !== -1) {
      this.panelTargets[tabIndex].classList.remove('hidden')
    }

    // Update tab styles
    this.tabTargets.forEach((tab, index) => {
      if (index === tabIndex) {
        tab.setAttribute('data-tab-active', '')
      } else {
        tab.removeAttribute('data-tab-active')
      }
    })
  }

  hideAllPanels() {
    this.panelTargets.forEach(panel => {
      panel.classList.add('hidden')
    })
  }

  showFirstPanel() {
    if (this.panelTargets.length > 0) {
      this.hideAllPanels()
      this.panelTargets[0].classList.remove('hidden')
      this.tabTargets[0]?.setAttribute('data-tab-active', '')
    }
  }
}