import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "subtotal", "tax", "taxRate", "shipping", "total",
    "totalCost", "totalProfit", "marginPercentage", "marginIndicator",
    "discount", "shippingDiscount"
  ]

  initialize() {
    // Bind methods to ensure proper 'this' context
    this.updateTotals = this.updateTotals.bind(this)
    this.handleAnyInputChange = this.handleAnyInputChange.bind(this)
  }

  connect() {
    // Set up event delegation at the document level for order items
    document.addEventListener('order-item:update', this.updateTotals)

    // Set up event listeners for all form inputs that affect totals
    this.setupInputListeners()

    // Initial calculation
    this.updateTotals()
  }

  disconnect() {
    document.removeEventListener('order-item:update', this.updateTotals)
    this.removeInputListeners()
  }

  setupInputListeners() {
    // Get the form containing our order
    const form = this.element.closest('form')
    if (!form) return

    // Monitor all relevant inputs using event delegation
    form.addEventListener('input', this.handleAnyInputChange)
    form.addEventListener('change', this.handleAnyInputChange)
  }

  removeInputListeners() {
    const form = this.element.closest('form')
    if (!form) return

    form.removeEventListener('input', this.handleAnyInputChange)
    form.removeEventListener('change', this.handleAnyInputChange)
  }

  handleAnyInputChange(event) {
    // Check if the changed input is one we care about
    const relevantInputs = [
      'order[tax_rate]',
      'order[shipping_cost]',
      'order[discount_amount]',
      'order[discount_type]',
      'order[shipping_discount_amount]',
      'order[shipping_discount_type]'
    ]

    if (event.target.name && relevantInputs.includes(event.target.name)) {
      // Debounce the update for better performance
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => {
        this.updateTotals()
      }, 100)
    }
  }

  calculateLineItemDiscount(itemElement) {
    const quantity = parseFloat(itemElement.querySelector('[data-order-item-target="quantity"]')?.value) || 0
    const price = parseFloat(itemElement.querySelector('[data-order-item-target="price"]')?.value) || 0
    const discountType = itemElement.querySelector('[data-order-item-target="discountType"]')?.value
    const discountAmount = parseFloat(itemElement.querySelector('[data-order-item-target="discountAmount"]')?.value || 0)

    const itemSubtotal = quantity * price
    if (!discountAmount) return 0

    return discountType === 'percentage'
      ? itemSubtotal * (discountAmount / 100)
      : Math.min(discountAmount, itemSubtotal)
  }

  updateTotals() {
    try {
      let subtotal = 0
      let totalCost = 0
      let lineItemDiscounts = 0

      // Calculate totals from all order items
      document.querySelectorAll('[data-controller="order-item"]').forEach(item => {
        const quantity = parseFloat(item.querySelector('[data-order-item-target="quantity"]')?.value) || 0
        const price = parseFloat(item.querySelector('[data-order-item-target="price"]')?.value) || 0
        const cost = parseFloat(item.querySelector('[data-order-item-target="cost"]')?.value) || 0

        const itemSubtotal = quantity * price
        const itemDiscount = this.calculateLineItemDiscount(item)

        subtotal += itemSubtotal
        totalCost += quantity * cost
        lineItemDiscounts += itemDiscount
      })

      // Get order-level values
      const orderDiscountType = document.querySelector('select[name="order[discount_type]"]')?.value
      const orderDiscountAmount = parseFloat(document.querySelector('input[name="order[discount_amount]"]')?.value || 0)
      const shipping = parseFloat(document.querySelector('input[name="order[shipping_cost]"]')?.value || 0)
      const taxRate = parseFloat(document.querySelector('input[name="order[tax_rate]"]')?.value || 0)
      const shippingDiscountType = document.querySelector('select[name="order[shipping_discount_type]"]')?.value
      const shippingDiscountAmount = parseFloat(document.querySelector('input[name="order[shipping_discount_amount]"]')?.value || 0)

      // Calculate discounts
      const orderDiscount = orderDiscountType === 'percentage'
        ? subtotal * (orderDiscountAmount / 100)
        : Math.min(orderDiscountAmount, subtotal)

      const shippingDiscount = shippingDiscountType === 'percentage'
        ? shipping * (shippingDiscountAmount / 100)
        : Math.min(shippingDiscountAmount, shipping)

      // Calculate final totals
      const totalDiscounts = lineItemDiscounts + orderDiscount
      const totalDiscounted = subtotal - totalDiscounts
      const tax = (totalDiscounted * taxRate) / 100
      const total = totalDiscounted + tax + shipping - shippingDiscount

      // Calculate profit metrics
      const profit = totalDiscounted - totalCost
      const marginPercentage = totalDiscounted > 0 ? ((profit / totalDiscounted) * 100) : 0

      this.updateDisplay({
        subtotal,
        tax,
        total,
        totalCost,
        profit,
        marginPercentage,
        orderDiscount: totalDiscounts,
        shippingDiscount,
        shipping,
        taxRate
      })
    } catch (error) {
      console.error('Error updating totals:', error)
    }
  }

  updateDisplay(values) {
    // Update all display targets
    Object.entries({
      subtotal: this.formatCurrency(values.subtotal),
      tax: this.formatCurrency(values.tax),
      total: this.formatCurrency(values.total),
      totalCost: this.formatCurrency(values.totalCost),
      totalProfit: this.formatCurrency(values.profit),
      marginPercentage: `${values.marginPercentage.toFixed(2)}%`,
      discount: `-${this.formatCurrency(values.orderDiscount)}`,
      shippingDiscount: `-${this.formatCurrency(values.shippingDiscount)}`,
      shipping: this.formatCurrency(values.shipping)
    }).forEach(([key, value]) => {
      if (this[`${key}Target`]) {
        this[`${key}Target`].textContent = value
      }
    })

    // Update tax rate if target exists
    if (this.hasTaxRateTarget) {
      this.taxRateTarget.textContent = values.taxRate
    }

    // Update margin indicator
    const indicator = this.marginIndicatorTarget
    indicator.classList.remove('bg-red-500', 'bg-green-500')
    indicator.classList.add(values.marginPercentage >= 8 ? 'bg-green-500' : 'bg-red-500')
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }
}